import company_favicon from '../assets/image/favicon.png'
import company_logo from '../assets/image/logo.svg'
import DefaultAvatar from '../assets/image/profile-img.svg';

const siteConfig = {
    company_name_with_tagline: 'Dietbk: The Best Diet App for Meal Planning and Weight Loss',
    company_name: 'Dietbk',
    company_favicon: company_favicon,
    lazy_suspense_delay: 300,
    company_logo: company_logo,
    default_avatar: DefaultAvatar,
}

export default siteConfig;