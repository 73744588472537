import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import siteConfig from "../../config/site-config";
import './Header.scss'
import path from "../../routes/path";
import avatar from '../../assets/image/avatar.png'
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../button/Button";
import Sidebar from "../Sidebar/Sidebar";
import subscribe from '../../assets/image/subscribe.svg'
import premium1 from '../../assets/image/king-icon.svg'
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../store/userSlice";
import { mealPlanAction } from "../../store/mealPlanSlice";
import { bucket_base_url } from "../../config/config";
import moment from "moment";
import AxiosServices from "../../networks/AxiosService";
import ApiUrlServices from "../../networks/ApiUrlServices";
import CustomPageLoader from "../customPageLoader";
import { userData } from "../../config/sessionKeys";

const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const first_login = params.get('first_login');
    const param = location.pathname;
    const [sidebar, setSidebar] = useState(false)
    const [getDataLoading, setGetDataLoading] = useState(false);
    const [differenceDays, setDifferenceDays] = useState(null);
    let usersData = JSON.parse(localStorage.getItem(userData));
    const getProfileData = useSelector((state) => state.userReducer.sessionData);
    const premiumData = useSelector((state) => state.mealPlanReducer.premiumData);
    const subscriptionIsNotExpired = moment().format('YYYY-MM-DD') < moment(getProfileData?.user_data?.sub_expire_date).format('YYYY-MM-DD');
    const landingPage = param === path.home || param === path.landingPage || param === path.about_us || param === path.terms_conditions ||
        param === path.balance_nutrition || param === path.realistic_goals || param === path.meal_planning ||
        param === path.physical_activity || param === path.support || param === path.equilibrium_point ||
        param === path.diet_guidelines || param === path.physical_activity_guide || param === path.privacy_policy ||
        param === path.weight_management || param === path.science_diet ? getProfileData.user_id ? false : true : false;

    const landingPageRoot = param === path.home || param === path.landingPage || param === path.about_us || param === path.terms_conditions ||
        param === path.balance_nutrition || param === path.realistic_goals || param === path.meal_planning ||
        param === path.physical_activity || param === path.support || param === path.equilibrium_point ||
        param === path.diet_guidelines || param === path.physical_activity_guide || param === path.privacy_policy ||
        param === path.weight_management || param === path.science_diet ? true : false;

    useEffect(() => {
        const today = moment();
        const targetDate = moment(getProfileData?.user_data?.create_date).format('YYYY-MM-DD');

        // Calculate the difference in days
        const dayDiff = today.diff(targetDate, 'days');

        setDifferenceDays(dayDiff);
        if (getProfileData?.user_id) {
            getProfileDatas()
        }
    }, [])

    const getProfileDatas = () => {
        // console.log(getProfileData)
        setGetDataLoading(true)
        const payload = {
            user_id: getProfileData?.user_id
        }
        AxiosServices.post(ApiUrlServices.GET_PROFILE, payload)
            .then(res => {
                console.log(res.data)
                // setUserProfileData(res.data.data)
                const profileData = res.data

                const userInfo = {
                    num_of_logs: usersData.num_of_logs,
                    success: true,
                    user_data: profileData.user_info,
                    user_id: usersData.user_id,
                    access_token: usersData.access_token,
                    refresh_token: usersData.refresh_token,
                    trust_this_device: usersData.trust_this_device
                }
                localStorage.setItem(userData, JSON.stringify(userInfo))
                dispatch(userAction.setSessionData(userInfo));
                setGetDataLoading(false)
            })
            .catch(err => {
                console.log(err)
                setGetDataLoading(false)
            }).finally(() => {
                setGetDataLoading(false)
            })
    }

    const handleSidebar = () => {
        setSidebar(!sidebar)
    }

    const logout = () => {
        navigate(path.login)
        localStorage.removeItem(userData);
        dispatch(userAction.setSessionData({}));
        dispatch(mealPlanAction.setMealPlan({
            breakfast: [],
            lunch: [],
            dinner: []
        }))
        dispatch(mealPlanAction.setPremiumData({}))
        dispatch(userAction.setSignUpData({ is_active: 'signUpForm_one', data: {} }));
    }

    return (
        <>
            {/* <CustomPageLoader pageLoader={getDataLoading} /> */}
            <header className={`header ${landingPageRoot && 'landing-page-header'}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-5 align-self-center">
                            <div className="header-logo">
                                <div onClick={() => handleSidebar()} className="header-menu-icon">
                                    {
                                        sidebar ? <Icon icon={'ic:round-close'} /> : <Icon icon={'ic:round-menu'} />
                                    }
                                </div>
                                <Link to={getProfileData.trust_this_device ? path.landingPage : path.home}>
                                    <img src={siteConfig.company_logo} alt={siteConfig.company_name} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 d-lg-block d-none align-self-center">
                            <div className="menu text-left">
                                {
                                    landingPage ?
                                        <ul>
                                            <li><NavLink to={path.home}>Home</NavLink></li>
                                            <li><NavLink to={path.about_us}>About Us</NavLink></li>
                                            <li>
                                                <a >Resources <span><Icon width={20} icon={'mingcute:down-line'} /></span></a>
                                                <ul className="submenu">
                                                    <li> <a href="https://dietbk-assets.s3.us-east-1.amazonaws.com/DietBK_UserManual.pdf" download="DietBK_UserManual.pdf">
                                                        User Manual
                                                    </a></li>
                                                    <li><NavLink to={path.weight_management}>The ABC's of weight management</NavLink></li>
                                                    <li><NavLink to={path.science_diet}>The Science of diet</NavLink></li>
                                                </ul>
                                            </li>
                                            {getProfileData.user_id && <li><NavLink to={path.subscribe}>Subscribe</NavLink></li>}
                                            {getProfileData.user_id && <li><NavLink to={path.support}>Support</NavLink></li>}
                                        </ul> :
                                        <ul>
                                            <li><NavLink
                                                className={
                                                    getProfileData?.user_data?.sub_expire_date ?
                                                        subscriptionIsNotExpired ? '' : 'disable'
                                                        : differenceDays <= premiumData?.free_period_days ? '' : 'disable'
                                                }
                                                to={path.meal_plan}>Meal entry</NavLink></li>
                                            <li><NavLink
                                                className={
                                                    getProfileData?.user_data?.sub_expire_date ?
                                                        subscriptionIsNotExpired ? '' : 'disable'
                                                        : differenceDays <= premiumData?.free_period_days ? '' : 'disable'
                                                }
                                                to={path.add_meal_item}>Add meal item</NavLink></li>
                                            <li><NavLink
                                                className={
                                                    getProfileData?.user_data?.sub_expire_date ?
                                                        subscriptionIsNotExpired ? '' : 'disable'
                                                        : differenceDays <= premiumData?.free_period_days ? '' : 'disable'
                                                }
                                                to={path.weight_entry}>Weight entry</NavLink></li>
                                            <li><NavLink
                                                className={
                                                    getProfileData?.user_data?.sub_expire_date ?
                                                        subscriptionIsNotExpired ? '' : 'disable'
                                                        : differenceDays <= premiumData?.free_period_days ? '' : 'disable'
                                                }
                                                to={path.analytics}>Analytics</NavLink></li>
                                            {/* <li><NavLink to={path.resources}>Resources</NavLink></li> */}
                                        </ul>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-7 d-flex justify-content-end">
                            {
                                !landingPage ?
                                    getProfileData?.user_data?.sub_expire_date ?
                                        subscriptionIsNotExpired ? '' :
                                            <Button
                                                btnText={'Subscribe'}
                                                onclickCallback={() =>
                                                    navigate(path.subscribe)
                                                }
                                                // disabled={loading}
                                                // isLoading={loading}
                                                imgIcon1={subscribe}
                                                btnClassName={param.slice(1) === 'subscribe' ? 'd-none' : 'subscribe-btn'}
                                                type="submit"
                                            />
                                        :
                                        <Button
                                            btnText={
                                                differenceDays <= premiumData?.free_period_days ?
                                                    (premiumData?.free_period_days - differenceDays) === 0 ?
                                                        'Trail ends today' : `Trial ends in ${premiumData?.free_period_days - differenceDays} days`
                                                    : 'Trial period expired'
                                            }
                                            onclickCallback={() =>
                                                navigate(path.subscribe)
                                            }
                                            // disabled={loading}
                                            // isLoading={loading}
                                            imgIcon1={subscribe}
                                            btnClassName={param.slice(1) === 'subscribe' ? 'd-none' : differenceDays <= premiumData?.free_period_days ? 'subscribe-btn thirteen-days-btn' : 'subscribe-btn'}
                                            type="submit"
                                        /> : ''
                            }
                            {
                                landingPage ?
                                    getProfileData.user_id ?
                                        <div className="auth-btn">
                                            <Button
                                                btnText={'Analytics'}
                                                onclickCallback={() => navigate(path.analytics)}
                                                // btnClassName={'login-btn'}
                                                btnClassName={
                                                    ` login-btn ${getProfileData?.user_data?.sub_expire_date ?
                                                        subscriptionIsNotExpired ? '' : 'disable'
                                                        : differenceDays <= premiumData?.free_period_days ? '' : 'disable'
                                                    }`
                                                }
                                                disabled={getProfileData?.user_data?.sub_expire_date ?
                                                    subscriptionIsNotExpired ? '' : true
                                                    : differenceDays <= premiumData?.free_period_days ? '' : true}
                                                type="button"
                                            />
                                            <Button
                                                btnText={'Logout'}
                                                onclickCallback={() => logout()}
                                                // disabled={loading}
                                                // isLoading={loading}
                                                type="button"
                                            />
                                        </div>
                                        : <div className="auth-btn">
                                            <Button
                                                btnText={'Login'}
                                                onclickCallback={() => navigate(path.login)}
                                                btnClassName={'login-btn'}
                                                type="button"
                                            />
                                            <Button
                                                btnText={'Create Profile'}
                                                onclickCallback={() => navigate(path.registration)}
                                                // disabled={loading}
                                                // isLoading={loading}
                                                type="button"
                                            />
                                        </div>
                                    :
                                    <div className="profile-section">
                                        <div className="profile-img">
                                            {
                                                getProfileData?.user_data?.profile_picture ?
                                                    <img src={`${bucket_base_url}${getProfileData.user_data?.profile_picture}`} alt="" /> :
                                                    <img src={avatar} alt="" />
                                            }
                                            <p>
                                                {first_login === 'true' && <span><strong>Welcome</strong></span>}
                                                <h4 className="one-line-clamp">
                                                    {/* {getProfileData?.user_data?.user_name} */}
                                                    {getProfileData?.user_data?.user_name.split(" ")[0].length > 10 ? `${getProfileData?.user_data?.user_name.slice(0, 10).split(" ")[0]}..` : getProfileData?.user_data?.user_name.split(" ")[0]}
                                                </h4>
                                            </p>
                                            <span className="down-icon"><Icon icon={'icon-park-outline:down'} /></span>
                                        </div>
                                        <div className="profile-menu-section">
                                            <div className="profile-top">
                                                <div className="profile-menu-box">
                                                    {
                                                        getProfileData?.user_data?.profile_picture ?
                                                            <img
                                                                onClick={() => navigate(path.my_profile)}
                                                                src={`${bucket_base_url}${getProfileData.user_data?.profile_picture}`} alt="" /> :
                                                            <img onClick={() => navigate(path.my_profile)} src={avatar} alt="" />
                                                    }
                                                    <div className="profile-content">
                                                        {/* <h4 className="one-line-clamp" onClick={() => navigate(path.my_profile)}>{getProfileData?.user_data?.user_name.length > 18 ? `${getProfileData?.user_data?.user_name.slice(0, 18)}...` : getProfileData?.user_data?.user_name}</h4> */}
                                                        <h4 className="one-line-clamp" onClick={() => navigate(path.my_profile)}>{getProfileData?.user_data?.user_name}</h4>
                                                        <p className="one-line-clamp">{getProfileData?.user_data?.email}</p>
                                                    </div>
                                                </div>
                                                <div className="view-profile-btn">
                                                    <Button
                                                        btnText={'View Profile'}
                                                        onclickCallback={() => navigate(path.my_profile)}
                                                        disabled={getProfileData?.user_data?.sub_expire_date ?
                                                            subscriptionIsNotExpired ? '' : true
                                                            : differenceDays <= premiumData?.free_period_days ? '' : true}
                                                        // isLoading={loading}
                                                        type="submit"
                                                    />
                                                </div>
                                            </div>
                                            <ul className="profile-menus">
                                                <li>
                                                    <a href="" onClick={() =>
                                                        getProfileData?.user_data?.sub_expire_date ?
                                                            subscriptionIsNotExpired ? navigate(path.my_memberships) :
                                                                navigate(path.subscribe) : navigate(path.subscribe)
                                                    } className="link">
                                                        <span><img src={premium1} alt="" /></span>
                                                        {
                                                            getProfileData?.user_data?.sub_expire_date ?
                                                                subscriptionIsNotExpired ? 'My Membership' : 'Subscribe' : 'Subscribe'
                                                        }
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="" onClick={() => logout()} className="link"><span><Icon icon={'ic:round-logout'} /></span> Logout</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </header>
            <Sidebar handleSideBar={handleSidebar} sideBar={sidebar} />
        </>
    )
}
export default Header;