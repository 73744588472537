import React from 'react'
import './Sidebar.scss';
import SidebarButton from './sidebarButton';
import { useTranslation } from "react-i18next";
import path from '../../routes/path';
import { Icon } from '@iconify/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sidebar = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const param = location.pathname;
    const getProfileData = useSelector((state) => state.userReducer.sessionData);
    const landingPage = param === path.home || param === path.landingPage || param === path.about_us || param === path.terms_conditions ||
        param === path.balance_nutrition || param === path.realistic_goals || param === path.meal_planning ||
        param === path.physical_activity || param === path.support || param === path.equilibrium_point ||
        param === path.diet_guidelines || param === path.physical_activity_guide || param === path.privacy_policy ||
        param === path.weight_management || param === path.science_diet ? getProfileData.user_id ? false : true : false;


    const menuItem = landingPage ?
        [
            {
                "name": `Home`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.home}`,
                "submenu": false
            },
            {
                "name": `About Us`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.about_us}`,
                "submenu": false
            },
            {
                "name": "Resources",
                "submenu": true,
                "submenu_item": [
                    {
                        "name": `User Manual`,
                        "path": ``
                    },
                    {
                        "name": `The ABC's of Weight Management`,
                        "path": `${process.env.PUBLIC_URL}${path.weight_management}`
                    },
                    {
                        "name": `The Science of diet`,
                        "path": `${process.env.PUBLIC_URL}${path.science_diet}`
                    }
                ]
            },
            {
                "name": `Subscribe`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.subscribe}`,
                "submenu": false
            },
            {
                "name": `Support`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.support}`,
                "submenu": false
            },
        ]
        : [
            {
                "name": `Meal entry`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.meal_plan}`,
                "submenu": false
            },
            {
                "name": `Add meal item`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.add_meal_item}`,
                "submenu": false,
            },
            {
                "name": `Weight entry`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.weight_entry}`,
                "submenu": false
            },
            {
                "name": `Analytics`,
                "icon": "",
                "path": `${process.env.PUBLIC_URL}${path.analytics}`,
                "submenu": false
            }
        ]

    return (
        <>
            <div onClick={props.handleSideBar} className={`sidebar-overlay ${props.sideBar ? 'active' : ''}`}></div>
            <div className={`sidebar ${props.sideBar ? 'active' : ''}`}>
                <div className='single-sidebar-menu'>
                    <nav>
                        <ul>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {menuItem.map((item, i) =>
                                    item.name === 'Subscribe' || item.name === 'Support' ?
                                        getProfileData?.user_id ?
                                            <li key={i}>
                                                {!item.submenu ?
                                                    <SidebarButton onClick={() => props.handleSideBar()} to={item.path} buttonText={item.name} icon={item.icon} />
                                                    :
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" data-id={i}>
                                                            <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${i}`} aria-expanded="false" aria-controls={`flush-collapse${i}`}>
                                                                <span className="service" ><Icon width={20} height={20} icon={item.icon} />{item.name}</span>
                                                            </button>
                                                        </h2>
                                                        <div id={`flush-collapse${i}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                            <ul>
                                                                {item.submenu_item.map((data, i) =>
                                                                    <li key={i}>
                                                                        <SidebarButton onClick={() => props.handleSideBar()} to={data.path} buttonText={data.name} />
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                            </li> : ''
                                        :
                                        <li key={i}>
                                            {!item.submenu ?
                                                <SidebarButton onClick={() => props.handleSideBar()} to={item.path} buttonText={item.name} icon={item.icon} />
                                                :
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" data-id={i}>
                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${i}`} aria-expanded="false" aria-controls={`flush-collapse${i}`}>
                                                            <span className="service" ><Icon width={20} height={20} icon={item.icon} />{item.name}</span>
                                                        </button>
                                                    </h2>
                                                    <div id={`flush-collapse${i}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <ul>
                                                            {item.submenu_item.map((data, i) =>
                                                                <li key={i}>
                                                                    <SidebarButton className={!data.path && 'disable'} onClick={() => props.handleSideBar()} to={data.path} buttonText={data.name} />
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </li>
                                )}
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Sidebar
