import {Storage} from "@aws-amplify/storage";
const config = require('./config');

export const S3_Pool_conf = {
    Auth: {
        identityPoolId: config.identityPoolId,
        region: config.region,
        userPoolId: config.cognitoUserPoolId,
        userPoolWebClientId: config.cognito_app_client_id,
    },
    Storage: {
        AWSS3: {
            bucket: config.bucket,
            region: config.region,
        }
    }
};

export function SetS3Config(bucket, level) {
    Storage.configure({
        bucket: bucket,
        level: level,
        region: config.region,
        identityPoolId: config.identityPoolId
    });
}

