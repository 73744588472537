import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "userReducer",
    initialState: {
        registerData: {},
        recoverPasswordData: {},
        sessionData: {},
        signUpData: { is_active: 'signUpForm_one', data: {} }
    },
    reducers: {
        setRegisterData(state, action) {
            state.registerData = action.payload
        },
        setRecoverPasswordData(state, action) {
            state.recoverPasswordData = action.payload
        },
        resetRecoverPasswordData(state, action) {
            state.recoverPasswordData = {}
        },
        setSessionData: (state, action) => {
            state.sessionData = action.payload;
        },
        setResetSessionData: (state, action) => {
            state.sessionData = action.payload;
        },
        setSignUpData: (state, action) => {
            state.signUpData = action.payload;
        },
    }
});

export const userAction = userSlice.actions;

export default userSlice;
