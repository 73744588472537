import {createSlice} from "@reduxjs/toolkit";

const mealPlanSlice = createSlice({
    name: "mealPlanReducer",
    initialState: {
        mealPlan: {},
        premiumData: {}
    },
    reducers: {
        setMealPlan(state, action) {
            state.mealPlan = action.payload
        },
        setPremiumData(state, action) {
            state.premiumData = action.payload
        }        
    }
});

export const mealPlanAction = mealPlanSlice.actions;

export default mealPlanSlice;
