import React, { Fragment, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from '../component/common/loader';
import Header from '../component/Header/Header';
import Footer from '../component/Footer/Footer';
import './scss/DefaultLayout.scss'
import { useDispatch, useSelector } from 'react-redux';
import { userData } from '../config/sessionKeys';
import { userAction } from '../store/userSlice';
import AxiosServices from '../networks/AxiosService';
import ApiUrlServices from '../networks/ApiUrlServices';
import { mealPlanAction } from '../store/mealPlanSlice';

const DefaultLayout = (props) => {
    const dispatch = useDispatch();
    let usersData = JSON.parse(localStorage.getItem(userData));
    const getProfileData = useSelector((state) => state.userReducer.sessionData);

    useEffect(() => {
        // console.log(Object.keys(usersData).length)
        if (Object?.keys(getProfileData)?.length > 0) {
            // console.log(usersData, getProfileData)
            if (usersData?.access_token !== getProfileData?.access_token) {
                // console.log(usersData.access_token, getProfileData.access_token)
                dispatch(userAction.setSessionData(usersData));
            }
        }
        if (usersData?.user_id) {
            console.log('usersData', usersData)
            dispatch(userAction.setSessionData(usersData));
        } else {
            console.log('getProfileData', getProfileData)
            dispatch(userAction.setSessionData({}));
        }
        getFreeInfo()
    }, [])

    const getFreeInfo = () => {
        AxiosServices.get(ApiUrlServices.GET_FREE_INFO)
            .then((response) => {
                console.log(response.data.data)
                dispatch(mealPlanAction.setPremiumData(response.data.data))
            })
            .catch((error) => {
                console.log(error.response)
            })
    }

    return (
        <Fragment>
            <Header />
            <div className="defaultayout-body">
                <Outlet />
                <Loader />
            </div>
            <Footer />
        </Fragment>
    )
}
export default DefaultLayout;