import React from "react";
import './Button.scss'
import { Icon } from "@iconify/react";

const Button = (props) => {
    return (
        <button
            type={props.type !== undefined ? props.type : 'submit'}
            title={props.title}
            ref={props.inputRef}
            onClick={props.onclickCallback}
            className={`button-1 ${props.btnClassName}`}
            disabled={props.isLoading || props.disabled}
        >
            {
                props.isLoading && (<Icon width={'24px'}  height={'24px'} icon={'eos-icons:loading'}/>)
            }
            {
                props.icon1 && (<span className="icon-1"><Icon icon={props.icon1}/></span>)
            }
            {
                props.imgIcon1 && (<span className="icon-1"><img src={props.imgIcon1} /></span>)
            }
            <span className="btn-text">{props.isLoading ? '' : props.btnText}</span>
            {
                props.icon2 && (<span className="icon-2"><Icon icon={props.icon2}/></span>)
            }
            {
                props.imgIcon2 && (<span className="icon-2"><img src={props.imgIcon2} /></span>)
            }
        </button>
    );
}
export default Button;
