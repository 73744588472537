import React, { Fragment, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './scss/AuthLayout.scss';
import AuthImg1 from '../assets/image/signup-img1.svg';
import AuthImg2 from '../assets/image/signup-img2.svg';
import AuthImg3 from '../assets/image/signup-img3.svg';
import AuthImg4 from '../assets/image/login-img.svg';
import AuthImg5 from '../assets/image/forgot-pass.svg';
import AuthImg6 from '../assets/image/reset-pass.svg';
import AuthBg from '../assets/image/auth-bg.png';
import { useDispatch, useSelector } from 'react-redux';
import AxiosServices from '../networks/AxiosService';
import ApiUrlServices from '../networks/ApiUrlServices';
import { mealPlanAction } from '../store/mealPlanSlice';

const AuthLayout = (props) => {
    const dispatch = useDispatch();
    const location = useLocation().pathname.slice(1);
    const signUpData = useSelector((state) => state.userReducer.signUpData);

    console.log(location);

    useEffect(() => {
        getFreeInfo()
    }, [])

    const getFreeInfo = () => {
        AxiosServices.get(ApiUrlServices.GET_FREE_INFO)
            .then((response) => {
                console.log(response.data.data)
                dispatch(mealPlanAction.setPremiumData(response.data.data))
            })
            .catch((error) => {
                console.log(error.response)
            })
    }

    const authImg = () => {
        if (location === 'registration') {
            if (signUpData?.is_active === 'signUpForm_one') {
                return <div style={{ backgroundImage: `url(${AuthBg})` }} className="auth-img"><img src={AuthImg1} alt="" /></div>;
            } else if (signUpData?.is_active === 'signUpForm_two') {
                return <div style={{ backgroundImage: `url(${AuthBg})` }} className="auth-img"><img src={AuthImg2} alt="" /></div>;
            } else if (signUpData?.is_active === 'signUpForm_three') {
                return <div style={{ backgroundImage: `url(${AuthBg})` }} className="auth-img"><img src={AuthImg3} alt="" /></div>;
            } else if (signUpData?.is_active === 'signUpForm_four') {
                return <div style={{ backgroundImage: `url(${AuthBg})` }} className="auth-img"><img src={AuthImg4} alt="" /></div>;
            }
        } else if (location === 'login') {
            return <div style={{ backgroundImage: `url(${AuthBg})` }} className="auth-img"><img src={AuthImg4} alt="" /></div>;
        } else if (location === 'forgot-password') {
            return <div style={{ backgroundImage: `url(${AuthBg})` }} className="auth-img"><img src={AuthImg5} alt="" /></div>;
        } else if (location === 'recovery-password') {
            return <div style={{ backgroundImage: `url(${AuthBg})` }} className="auth-img"><img src={AuthImg6} alt="" /></div>;
        }
    }

    return (
        <Fragment>
            <div className="authentication-layout-wrapper">
                <div className='row p-0 m-0'>
                    <div className='col-lg-8 d-lg-block d-none p-0 m-0'>
                        {authImg()}
                    </div>
                    <div className='col-lg-4 col-md-12 p-0 m-0 auth-right'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AuthLayout;