export const maintenance = false;
export const web_base_url = 'https://www.dietbk.com'
export const api_base_url = 'https://api.dietbk.com/api'
export const cookie_url_endpoint = ''
export const auth0_redirected_endpoint = ''
export const social_api_base_url = ''
export const cloudfront_base_url = ''
export const bucket_base_url = 'https://diet-bk-public.s3.us-west-2.amazonaws.com/'
export const cognitoUserPoolId = ""
export const identityPoolId = "us-west-2:e85fb99e-a43e-48e8-8dba-d7bc19f80f15"
export const bucket = 'diet-bk-public'
export const region = 'us-west-2'
export const cognito_app_client_id = ""
export const auth0_app_client_id = ''
export const DEFAULT_LANGUAGE = 'en'
export const messengerChatId = ''
export const web_socket_url = ''
export const environment = 'production'
