export function pageTitle(title) {
    document.title = title
}

// export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z-]+\.[a-zA-Z]{2,}$/;
export const validateAlphabetOnly = /^[A-Za-z.,][A-Za-z.,]*( [A-Za-z.,]+)*$/;
export const validateNumberOnly = /^[0-9.]+$/;
export const validateNumber = /^[0-9]+$/;
export const validateNumberAndTwoDecimalNumber = /^\d{0,3}(\.\d{0,2})?$/;
export const validateAlphaNumericWithSpace = /^[a-zA-Z0-9 ]+$/;
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const unitTypeOption = [
    { label: 'Gram', value: 'Gram' },
    { label: 'Oz', value: 'Oz' },
    { label: 'Per item', value: 'Per item' },
    { label: 'Cups', value: 'Cups' },
    { label: 'Tablespoons', value: 'Tablespoons' },
    { label: 'Teaspoons', value: 'Teaspoons' },
]

export const meterToCentimeter = (meters) => (meters * 100).toFixed(2);
export const centimeterToMeter = (meters) => (meters / 100).toFixed(2);

export function lbsToKg(pounds) {
    return (pounds / 2.20462).toFixed(2);
}


export const kgToLbs = (kg) => (kg * 2.20462).toFixed(2);


export function lbsToKgFull(pounds) {
    return (pounds / 2.20462);
}

export const kgToLbsFull = (kg) => (kg * 2.20462);


export const feetToCmt = (feet, inches) => {
    const cmTotal = feet * 30.48 + inches * 2.54;
    return cmTotal;
};

export const cmToInFt = (cm) => {
    const totalInches = cm / 2.54;
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;
    return { feet, inches: parseFloat(inches.toFixed(2)) };
}


function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const removeGarbageCharOnFileName = (fileName) => {
    const arr = fileName.split(".");
    const ext = arr.pop();

    let name = arr.join(".");
    const filterData = name.replace(/[^a-zA-Z0-9-_]/g, '');

    const finalStr = `${filterData}${generateString(8)}.${ext}`;
    return finalStr;
}

const fileFormatValidator = (name, formatArr) => {
    let flag = false;
    formatArr.map(format => {
        if (name.includes(format)) {
            flag = true;
        }
    })
    return flag;
}

export const fileSizeValidator = (size, type, name, formatArr = []) => {
    // const fileSizeMB = Math.round((size / 1024 / 1024)); // in MB
    const fileSizeKB = Math.round((size / 1024)); // in KB

    const baseFileSizeFormat = {
        "video": 500,
        "attachment": 50,
        "preview": 500,
        "image": 1000
    }

    const filetype = {
        "video": [".mp4", ".m4v"],
        "attachment": [".zip", ".rar", ".tar"],
        "preview": [".mp4", ".m4v"],
        "image": ["image"]
    }

    if (name) {
        const formArr = formatArr.length ? formatArr : filetype[type];
        if (!fileFormatValidator(name, formArr)) {
            return "format_mismatch";
        }
    }

    if (!baseFileSizeFormat[type]) {
        return false;
    }

    if (fileSizeKB > baseFileSizeFormat[type]) {
        return false;
    }

    return true;
}

export const validateImageSize2 = async (file, cb) => {

    if (file) {
        let img = new Image()
        img.src = window?.URL?.createObjectURL(file);
        img.onload = () => {
            cb(img)
        }
    }
}

export const genericImageValidatorResizer = (file, config, setError, errObj, setImage, imageObj, e, onResizeImage, isNullErrorObj) => {

    const resolveAndSetErrors = (error) => {
        if (errObj) {
            setError(prev => ({
                ...prev,
                [errObj]: error
            }))
        } else {
            setError(error)
        }
    }
    const resolveAndSetImage = (editedFile) => {
        if (imageObj) {
            setImage(prev => ({
                ...prev,
                [imageObj]: editedFile
            }))
        } else {
            setImage(editedFile);
        }
    }

    const validation = fileSizeValidator(file?.size, "image", file?.type);
    if (validation === true) {
        validateImageSize2(file, (img) => {
            if (img.height >= config.height && img.width >= config.width) {
                resolveAndSetErrors("");
                validateImageSize2(file, async (img) => {
                    if (img.height > config.height || img.width > config.width) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.addEventListener("load", () => {
                            onResizeImage(reader.result, file.name);
                        });

                        return;
                    } else {
                        resolveAndSetImage(file);
                    }
                })
            } else {
                e.target.value = "";
                if (!isNullErrorObj) {
                    setImage({});
                } else {
                    setImage(null);
                }
                resolveAndSetErrors(`Image is too small. Please  upload larger image than ${config.width}X${config.height} resolution.`);
            }
        })
    } else if (validation === false) {
        if (!isNullErrorObj) {
            setImage({});
        } else {
            setImage(null);
        }
        resolveAndSetErrors("Please select an image less than 1000 kb");
    } else if (validation === "format_mismatch") {
        e.target.value = "";
        resolveAndSetErrors(`Please select an image`)
    }
}