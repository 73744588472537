const ApiUrlServices = {
  LOGIN: '/login',
  REGISTER: '/register',
  REFRESH_TOKEN: '/refreshed_tokens',
  INSERT_DISH: '/add_meal_item',
  SAVE_TO_WEIGHT: '/save_to_weights',
  WEIGHT_HISTORY: (user_id) => `/weights/${user_id}`,
  SAVE_MEAL_PLAN: '/save_meals_to_db',
  GET_MEAL_PLAN: (form_date,to_date) => `/get_meal_data?from_date=${form_date}&to_date=${to_date}`,
  GET_ALL_DISHES: (user_id) => `/get_all_dishes/${user_id}`,
  FORGOT_PASSWORD: '/AuthenticateUser',
  RESET_PASSWORD: '/RestPassword',
  GET_PROFILE: `/getUserInfo`,
  UPDATE_USER_INFO: '/updateUser',
  CHANGE_PASSWORD: '/ChangePassword',
  DAILY_CALORIES_BUDGET: '/daily_calorie_budget',
  INITIATE_PAYMENT: '/initiate_payment',
  GET_SUBSCRIPTION: (user_id) => `/get_subscription_info/${user_id}`,
  DASHBOARD_DATA: '/weight_progression',
  GET_FREE_INFO: '/get-fee-info',
  SEND_EMAIL: '/support/send_message',
};

export default ApiUrlServices;
